import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Country } from 'src/app/models/market-collection/market-collection.model';
import { CountryService } from '../../services/country.service';
import { UserApiService } from '../../services/user-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
   selector: 'app-filter-by-country-popup',
   templateUrl: './filter-by-country-popup.component.html',
   styleUrls: ['./filter-by-country-popup.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class FilterByCountryPopupComponent implements OnInit {
   private destroy$ = new Subject<void>();
   countriesLoader: boolean = false;
   selectedCountry: Country | null = null;
   allCountries: Country[] = []
   coveredCountries: Country[] = []
   notCoveredCountries: Country[] = []
   selectedCountryCode!: string

   constructor(
      public activeModal: NgbActiveModal,
      private countryService: CountryService,
      private cdr: ChangeDetectorRef,
      private userApiServices: UserApiService,
      private router: Router,
      private route: ActivatedRoute,
   ) { }

   ngOnInit(): void {
      // this.getUserDefaultCountry()
      this.getCountriesData();

      this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
         if (params.has('country')) {
            this.selectedCountryCode = params.get('country') as string;
         }
      })

      // this.countryService.filterCountry$.pipe(takeUntil(this.destroy$)).subscribe(country => {
      //    this.selectedCountry = country;
      // });

   }

   trackByFn(index: number, item: Country): any {
      return item.id;
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getCountriesData() {
      this.countriesLoader = true;
      this.countryService.getCountries().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            if (response) {
               this.allCountries = response;

               this.coveredCountries = this.allCountries
                  .filter((country: Country) => country.stocksCovered)
                  .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0));

               // this.notCoveredCountries = this.allCountries
               //    .filter((country: Country) => !country.stocksCovered)
               //    .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0));

               this.countriesLoader = false;
               this.cdr.markForCheck();
            }
         },
         error: (error) => {
            console.error(error);
            this.countriesLoader = false;
            this.cdr.markForCheck();
         }
      });
   }

   onCountryChange(country: Country) {
      this.selectedCountry = country;
      // this.countryService.setFilterCountry(country)
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: { country: this.selectedCountry.countryCode, page: 1, },
         queryParamsHandling: 'merge'
      });
      this.countryService.closeFilterByCountriePopup()
   }
   // onCountryChange(country: Country) {
   //    this.selectedCountry = country;
   //    this.countryService.setFilterCountry(country)
   //    this.countryService.closeFilterByCountriePopup()
   // }

   // private getUserDefaultCountry() {
   //    this.userApiServices.getUserData().pipe(takeUntil(this.destroy$)).subscribe({
   //       next: (data) => {
   //          if (data) {
   //             const user = data;
   //             console.log(user)
   //          }
   //       },
   //       error: (error) => {
   //          console.error(error);
   //       }
   //    })
   // }

}
