import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, Observable, of, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
   MarketCoverage,
   MarketCoverageResponse,
} from '../models/market-coverage.model';
import { PeriodRole, Role } from 'src/app/models/subscription-info.model';
import { timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface Feature {
   isAvailable: boolean;
   name: string;
   featureCount: number;
   featureInfo: string;
}

declare global {
   interface Window {
      Plaid: any;
   }
}

@Injectable({
   providedIn: 'root',
})
export class PricingService {
   url: string = environment.apiUrl;
   roleHierarchy: PeriodRole[] = [
      'free',
      'starter_monthly',
      'starter_yearly',
      'premium_monthly',
      'premium_yearly',
      'premium_plus_monthly',
      'premium_plus_yearly',
   ];
   private marketCoverageCache$!: Observable<MarketCoverage[]>;

   private plansTabVisible: BehaviorSubject<'Non-Professional' | 'Professional'> = new BehaviorSubject<'Non-Professional' | 'Professional'>('Non-Professional');
   getPlansTabVisibility = this.plansTabVisible.asObservable()

   constructor(private http: HttpClient) { }

   setPlansTabVisibility(tab: 'Non-Professional' | 'Professional') {
      this.plansTabVisible.next(tab)
   }

   // fetchBrokeragesCoverage<T>(countryCode: string): Observable<T> {
   //    let params = new HttpParams().set('country_code', countryCode)

   //    return this.http.post<T>(`${this.url}/api/trading/pricing/brokerage-coverage`, { params })
   // }

   fetchBrokeragesCoverage<T>(countryCode: string): Observable<T> {
      return this.http.get<T>(
         `${this.url}/api/trading/pricing/brokerage-coverage`
         // ?country_code=${countryCode}
      );
   }

   fetchInstitutions(query: string): Promise<any> {
      return this.http.get<any>(
         `${this.url}/api/plaid/search-brokerage/${query}`
      ).toPromise();
   }

   createLinkToken(): Promise<any> {
      return this.http.post<any>(
         `${this.url}/api/plaid/create_link_token/`,
         {}
      ).toPromise();
   }

   getJwtToken(): Promise<any> {
      return this.http.get<any>(`${this.url}/api/featurebase/create-jwt-token/`).toPromise();
   }

   plaidWebhook(params: any): Promise<any> {
      return this.http.post<any>(
         `${this.url}/api/plaid/webhook/`,
         params
      )
         .pipe(
            timeout(100000), // timeout 100 seconds (100000 ms)
            catchError(error => {
               console.error('Request error or timeout', error);
               return throwError(() => error);
            })
         ).toPromise();
   }

   getMarketCoverage(): Observable<MarketCoverage[]> {
      this.marketCoverageCache$ =
         this.marketCoverageCache$ ??
         this.fetchMarketCoverage().pipe(shareReplay());
      return this.marketCoverageCache$;
   }

   private fetchMarketCoverage(): Observable<MarketCoverage[]> {
      return this.http
         .get<MarketCoverageResponse>(`${this.url}/api/portfolio/coverage`)
         .pipe(map((response) => response.data));
   }

   getBrokerageCountries(): Observable<any> {
      return this.http
         .get<any>(
            `${this.url}/api/trading/pricing/brokerage-coverage/countries`
         )
         .pipe(map((response) => response.data));
   }

   // fetchMarketCoverage<T>(): Observable<T> {
   //    return this.http.get<T>(`${this.url}/api/portfolio/coverage`)
   // }

   isHigherTier(role1: PeriodRole, role2: PeriodRole): boolean {
      // console.log(role1)
      // console.log(role2)
      if (role1 === null || role2 === null) return false;
      return (
         this.roleHierarchy.indexOf(role1) > this.roleHierarchy.indexOf(role2)
      );
   }

   isLowerTier(role1: PeriodRole, role2: PeriodRole): boolean {
      if (role1 === null || role2 === null) return false;
      return (
         this.roleHierarchy.indexOf(role1) < this.roleHierarchy.indexOf(role2)
      );
   }

   async getSubscriptionPlansData(countryCode: string | null) {
      const response: any = await lastValueFrom(
         this.http.get(`${this.url}/api/v2/plans?country=${countryCode}`)
      );
      const data = response.data;

      const freePlan = {
         id: 'free',
         type: 'Free',
         monthlyPriceId: '',
         yearlyPriceId: '',
         monthlyPrice: 0,
         yearlyPrice: 0,
         currency: data.starter?.year.currency.toUpperCase(),
         planIncludes: [],
      };

      const starterPlan = {
         id: 'starter',
         type: 'Starter',
         monthlyPriceId: data.starter?.month.id,
         yearlyPriceId: data.starter?.year.id,
         monthlyPrice: data.starter?.month.price,
         yearlyPrice: data.starter?.year.price,
         currency: data.starter?.year.currency.toUpperCase(),
         planIncludes: ['Screener Basic'],
      };

      const premiumPlan = {
         id: 'premium',
         type: 'Premium',
         monthlyPriceId: data.premium?.month.id,
         yearlyPriceId: data.premium?.year.id,
         monthlyPrice: data.premium?.month.price,
         yearlyPrice: data.premium?.year.price,
         currency: data.premium?.year.currency.toUpperCase(),
         planIncludes: ['Screener', 'Portfolio'],
      };

      const premiumPlusPlan = {
         id: 'premium_plus',
         type: 'Premium Plus',
         monthlyPriceId: data.premium_plus?.month.id,
         yearlyPriceId: data.premium_plus?.year.id,
         monthlyPrice: data.premium_plus?.month.price,
         yearlyPrice: data.premium_plus?.year.price,
         currency: data.premium_plus?.year.currency.toUpperCase(),
         planIncludes: ['Screener', 'Portfolio', 'Trade'],
      };

      const professionalPlan = {
         id: 'professional',
         type: 'Professional',
         monthlyPriceId: data.professional?.month.id,
         yearlyPriceId: data.professional?.year.id,
         monthlyPrice: data.professional?.month.price,
         yearlyPrice: data.professional?.year.price,
         currency: data.professional?.year.currency.toUpperCase(),
         planIncludes: ['For Professional Use'],
      };

      return {
         freePlan,
         starterPlan,
         premiumPlan,
         premiumPlusPlan,
         professionalPlan
      };
   }

   getFreeFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Sample Detailed Shariah Report',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Sample Shariah Compliance History',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Sample ESG Reports (Coming Soon)',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Daily Stock Coverage Requests',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Basic Compliance Alerts',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Screener with basic filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlist (10 Symbols)',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Basic Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Brokerage Connected Portfolios',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Portfolio',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Basic Portfolio Compliance Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Brokerage Connected Trading',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Free Education & Coaching Materials',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Email Support',
            featureCount: 0,
            featureInfo: '',
         },
      ]
   }

   getStarterFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status & Musaffa Star Rating',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed Shariah Report',
            featureCount: 50,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Shariah Compliance History',
            featureCount: 50,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed ESG Reports (Coming Soon)',
            featureCount: 50,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Daily Stock Coverage Requests',
            featureCount: 3,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Basic Compliance Alerts',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Screener with intermediate filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlist (25 symbols per watchlist)',
            featureCount: 5,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Brokerage Connected Portfolios',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Portfolio',
            featureCount: 3,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Basic Portfolio Compliance Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Brokerage Connected Trading',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Intermediate Education & Coaching Materials',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Email & In-App Support',
            featureCount: 0,
            featureInfo: '',
         },
      ];
   }

   getPremiumFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status & Musaffa Star Rating',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed Shariah Report',
            featureCount: 150,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Shariah Compliance History',
            featureCount: 150,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed ESG Reports (Coming Soon)',
            featureCount: 150,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Daily Stock Coverage Requests',
            featureCount: 10,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Advanced Compliance Alerts',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Screener with advanced filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlist (30 symbols per watchlist)',
            featureCount: 15,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Brokerage Connected Portfolios',
            featureCount: 3,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Portfolio',
            featureCount: 5,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Portfolio Compliance Tracker & Analytics',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Automated Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Brokerage Connected Trading (Beta)',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator with live portfolio import',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Premium Education & Coaching Materials, Monthly Webinars and QnAs.',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Priority Support via Live Chat',
            featureCount: 0,
            featureInfo: '',
         },
      ];
   }

   getPremiumPlusFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status & Musaffa Star Rating',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed Shariah Report',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Shariah Compliance History',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Detailed ESG Reports (Coming Soon)',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Daily Stock Coverage Requests',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Smart Compliance & Price Alerts',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Screener with advanced filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlists & Symbols',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Advanced Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Advanced Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Brokerage Connected Portfolios',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Manual Portfolio',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Advanced Portfolio Compliance Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Automated Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Brokerage Connected Trading (Beta)',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator with live portfolio import',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'access to entire Musaffa Library. Access to Shariah Scholars. ',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Premium Dedicated Priority Support via WhatsApp',
            featureCount: 0,
            featureInfo: '',
         },
      ];
   }
}
