<div class="modal-header border-0 d-flex align-items-center justify-content-between">
   <h4 class="modal-title fw-600 lh-sm">Connect Your Portfolio</h4>
   <button class="text-black p-1 rounded-3 btn shadow-none" (click)="closeConnectPopup()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">
         <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
   </button>
</div>
<div class="modal-body custom-scroll-bar border-0">
   <div class="connect-cards-grid gap-3 gap-lg-4">

      <article class="d-flex align-items-center justify-content-end flex-column gap-3 p-3 px-lg-4 pb-lg-4 text-center">
         <div class="d-flex align-items-center mx-auto recommended-chip py-1 px-2 gap-2 rounded-pill">
            <span class="rounded-circle  d-block p-1 bg-success"></span>
            <p class="mb-0 text-black">Recommended for automated compliance alerts!</p>
         </div>
         <img src="/assets/images/link-broker-img.png" class="img-fluid mx-auto" alt="Link Broker image">
         <h4 class="mb-0 text-black fw-600 card-h4">Link Your Broker</h4>
         <p class="mb-2 card-p">
            Link your existing broker account to import all your holdings & transactions in 1 click!
         </p>
         <ng-container *ngIf="userNewRole !== 'premium_plus' && userNewRole !== 'professional'">
            <ng-container *ngIf="maxBrokeragePortfoliosCount === 0; else showBrokerageCount">
               <div class="mx-auto portfolios-count-chip py-1 px-3 gap-2 rounded-pill upgrade-chip">
                  <p class="d-flex align-items-center gap-2 mb-0">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z"/>
                     </svg>
                     <span>Upgrade to Unlock</span>
                  </p>
               </div>
            </ng-container>
            <ng-template #showBrokerageCount>
               <div class="mx-auto portfolios-count-chip py-1 px-3 gap-2 rounded-pill" [ngClass]="{'limit-reached': currentBrokeragePortfoliosCount >= maxBrokeragePortfoliosCount}" >
                  <p class="mb-0">
                     <span class="fw-600 me-2">{{ currentBrokeragePortfoliosCount }}/{{ maxBrokeragePortfoliosCount }}</span>Brokerages Connected
                  </p>
               </div>
            </ng-template>
         </ng-container>
         <button class="gradient-btn d-flex align-items-center justify-content-center  btn shadow-none fw-500 text-white rounded-3 card-btn" (click)="openBrokeragesPopup()">
            <span *ngIf="currentBrokeragePortfoliosCount >= maxBrokeragePortfoliosCount; else showConnectPortfolioText">
               <svg class="mb-1 me-1 d-inline-block" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m13.25 6.685-1.928.48-2.407-3a1.125 1.125 0 0 0-1.755 0l-2.408 3-1.965-.48A1.132 1.132 0 0 0 1.422 8.05l1.328 4.665a.75.75 0 0 0 .75.548h9a.75.75 0 0 0 .75-.548l1.335-4.665a1.132 1.132 0 0 0-1.335-1.365m-12.187-.81a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875m13.875 0a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875M8 2.688A.937.937 0 1 0 8 .813a.937.937 0 0 0 0 1.875M12.5 16h-9a.75.75 0 1 1 0-1.5h9a.75.75 0 1 1 0 1.5" fill="currentColor"/>
               </svg>
               Upgrade
            </span>
            <ng-template #showConnectPortfolioText>
               <span>Connect Portfolio</span>
            </ng-template>
         </button>
      </article>

      <article class="d-flex align-items-center justify-content-end flex-column gap-3 p-3 px-lg-4 pb-lg-4 text-center">
         <img src="/assets/images/add-manual-img.png" class="img-fluid mx-auto" alt="Link Broker image">
         <h4 class="mb-0 text-black fw-600 card-h4">Add Manually</h4>
         <p class="mb-2 card-p">
            If your broker isn’t listed, you can still add your holdings and transactions manually
         </p>
         <ng-container *ngIf="userNewRole !== 'premium_plus' && userNewRole !== 'professional'">
            <div class="mx-auto portfolios-count-chip py-1 px-3 gap-2 rounded-pill" [ngClass]="currentManualPortfoliosCount >= maxManualPortfoliosCount ? 'limit-reached' : ''">
               <p class="mb-0"><span class="fw-600 me-2">{{ currentManualPortfoliosCount }}/{{ maxManualPortfoliosCount }}</span>Manual Portfolios Added</p>
            </div>
         </ng-container>
         <button class="gradient-border btn d-flex align-items-center justify-content-center shadow-none fw-500 rounded-3 card-btn manually-btn" (click)="openManualPopup()">
            <span *ngIf="currentManualPortfoliosCount >= maxManualPortfoliosCount; else showAddManuallyText">
               <svg class="mb-1 me-1 d-inline-block" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m13.25 6.685-1.928.48-2.407-3a1.125 1.125 0 0 0-1.755 0l-2.408 3-1.965-.48A1.132 1.132 0 0 0 1.422 8.05l1.328 4.665a.75.75 0 0 0 .75.548h9a.75.75 0 0 0 .75-.548l1.335-4.665a1.132 1.132 0 0 0-1.335-1.365m-12.187-.81a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875m13.875 0a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875M8 2.688A.937.937 0 1 0 8 .813a.937.937 0 0 0 0 1.875M12.5 16h-9a.75.75 0 1 1 0-1.5h9a.75.75 0 1 1 0 1.5" fill="currentColor"/>
               </svg>
               Upgrade
            </span>
            <ng-template #showAddManuallyText>
               <span>Add Manually</span>
            </ng-template>
         </button>
      </article>

   </div>
</div>
