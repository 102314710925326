import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { UserApiService } from '../../services/user-api.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-premium-member-greeting-popup',
  templateUrl: './premium-member-greeting-popup.component.html',
  styleUrls: ['./premium-member-greeting-popup.component.scss']
})
export class PremiumMemberGreetingPopupComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   userNewRole: Role = null;
   planText: string = '';
   userDataLoader: boolean = false;
   isUserPhoneNumberAvailable: boolean = false;
   userPlan: string = ''

   constructor(
      private congratulationsModal: NgbActiveModal,
      private userApiServices: UserApiService,
      private sharedService: SharedService
   ) {}

   ngOnInit(): void {
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userNewRole = data.newRole;
         });

      this.getUserDetails()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getPlanText(role: string) {
      switch (role) {
         case 'STARTER':
            return 'Musaffa Starter';
         case 'PREMIUM':
            return 'Musaffa Premium';
         case 'PREMIUM_PLUS':
            return 'Musaffa Premium Plus';
         case 'PROFESSIONAL':
            return 'Musaffa Professional';
         default:
            return '';
      }
   }

   private getUserDetails() {
      this.userDataLoader = true;
      this.userApiServices.getUserData().pipe(takeUntil(this.destroy$)).subscribe({
         next: (data) => {
            if(data) {
               this.userPlan = data.app_role.toUpperCase();
               this.isUserPhoneNumberAvailable = data.phone ? true : false ;
               this.userDataLoader = false;
            }
         },
         error: (error) => {
            console.error(error);
            this.userDataLoader = false;
         },
         complete: () => {
            this.userDataLoader = false;
         },
      })
   }

   closeGreetingsPopup() {
      this.congratulationsModal.close();
      if(!this.isUserPhoneNumberAvailable) {
         this.openPhoneNumberPopup()
      }
   }

   openPhoneNumberPopup() {
      this.sharedService.openPersonalizedAssistancePopup()
   }

}
