<div class="modal-header border-0 p-2 py-lg-3 px-lg-4">
   <h4 class="modal-title" id="modal-basic-title">Select Country</h4>
   <button type="button" class="btn shadow-none p-1 bg-light rounded-3 modal-header-close-btn" aria-label="Close" (click)="activeModal.dismiss()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#000" fill="none">
         <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
   </button>
</div>
<div class="modal-body custom-scroll-bar p-3 p-lg-4">
   <div class="mb-4">
      <h5 class="mb-3 fw-500">Covered Countries</h5>

      <div class="countries-grid" *ngIf="countriesLoader">
         <div class="skeleton-loader d-block pt-5 pb-3 w-100 rounded-3" *ngFor="let i of [].constructor(10)"></div>
      </div>

      <ul role="list" class="m-0 p-0 countries-grid" *ngIf="!countriesLoader">
         <li class="d-flex country-radio-wrapper p-0" *ngFor="let country of coveredCountries">
            <input class="country-input" type="radio" name="country" [value]="country" [id]="country.id" [(ngModel)]="selectedCountry" (ngModelChange)="onCountryChange(country)">
            <label class="d-flex align-items-start rounded-3 flex-grow-1 country-label" [for]="country.id" [ngClass]="{'selected': selectedCountryCode === country.id}">
               <img [src]="'/assets/flags/1x1/' + country.countryCode.toLowerCase() + '.svg'" [alt]="country.country" class="bg-light border flex-shrink-0 rounded-circle country-image" width="22px" height="22px">
               <div class="d-grid gap-1">
                  <h6 class="mb-0 fw-500">{{ country.country }}</h6>
                  <p class="mb-0">
                     {{ country.stockExchanges.join(', ') }}
                  </p>
               </div>
            </label>
         </li> 
      </ul>
   </div>

   <!-- <div>
      <h5 class="mb-3 fw-500">All Countries</h5>

      <div class="countries-grid" *ngIf="countriesLoader">
         <div class="skeleton-loader d-block pt-5 pb-3 w-100 rounded-3" *ngFor="let i of [].constructor(10)"></div>
      </div>

      <ul role="list" class="m-0 p-0 countries-grid" *ngIf="!countriesLoader">
         <li class="d-flex country-radio-wrapper p-0" *ngFor="let country of notCoveredCountries">
            <input class="country-input" type="radio" name="country" [value]="country" [id]="country.id" [(ngModel)]="selectedCountry" (ngModelChange)="onCountryChange(country)">
            <label class="d-flex align-items-start rounded-3 flex-grow-1 country-label" [for]="country.id" [ngClass]="{'selected': selectedCountry?.id === country.id}">
               <img [src]="'/assets/flags/1x1/' + country.countryCode.toLowerCase() + '.svg'" [alt]="country.country" class="bg-light border flex-shrink-0 rounded-circle country-image" width="22px" height="22px">
               <div class="d-grid gap-1">
                  <h6 class="mb-0 fw-500">{{ country.country }}</h6>
                  <p class="mb-0">
                     {{ country.stockExchanges.join(', ') }}
                  </p>
               </div>
            </label>
         </li>
      </ul>
   </div> -->
</div>
