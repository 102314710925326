<div class="modal-body border-0 text-center">
   <ng-container *ngIf="canDissmisPopup">
      <button type="btn" class="close-popup-btn text-black p-1 rounded-3 btn shadow-none" (click)="closePersonalizedAssistancePopup()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
   </ng-container>

   <img src="/assets/images/support-agent.png" class="img-fluid mx-auto" alt="Support Agent Image">
   <h3 class="text-black fw-600">
      {{ popupTitle }} 
   </h3>
   <p class="mb-4">
      {{ popupPara }}
   </p>
   <form class="mx-auto" [formGroup]="phoneNumberForm" (submit)="submitPhoneNumber()">
      <div>
         <label class="form-label d-block text-start mb-2 fw-500">Enter your mobile number</label>
         <div class="d-flex align-items-center phone-number-wrapper">
            <ng-select class="country-code-ng-select" formControlName="countryCode" [items]="countryCodes" [clearable]="false" >
               <ng-template ng-label-tmp let-item="item">
                  <span class="text-start d-block">{{ item }}</span>
               </ng-template>
               <ng-template ng-option-tmp let-item="item">
                  <span class="text-start d-block">{{ item }}</span>
               </ng-template>
            </ng-select>
            <input class="flex-grow-1 border-0 bg-white" formControlName="phoneNumber" placeholder="Enter Phone Number" type="text" (input)="trimAndValidate($event)">
         </div>
         <small class="d-block text-start mt-1">Please enter your valid mobile number for verification</small>
      </div>
      <button type="submit" class="border-0 d-block submit-btn gradient-bg-btn" [disabled]="formLoader || phoneNumberForm.invalid">
         <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="formLoader">
            <span class="visually-hidden">Loading...</span>
         </div>
         <span>{{ formLoader ? 'Submitting...' : 'Submit' }}</span>
      </button>
   </form>
</div>

